<template>
    <div class="flags">
        <div class="flag-list">
            <v-img style="display: inline-block; width: 25px; margin: 2px; margin-top: 22px; border: 0.5px solid gray;" src="@/assets/images/labelSwitcher/us.svg" @click="changeLocale('en')"/>
            <v-img style="display: inline-block; width: 25px; margin: 2px; border: 0.5px solid gray;" src="@/assets/images/labelSwitcher/id.svg" @click="changeLocale('id')"/>
            <v-img style="display: inline-block; width: 25px; margin: 2px; border: 0.5px solid gray;" src="@/assets/images/labelSwitcher/jp.svg" @click="changeLocale('jp')"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LocaleSwitcher",
        methods: {
            changeLocale: function (loc){
                this.$i18n.locale=loc
            },
        }
    };
</script>

<style>
.flags .flag-list v-img{
  width: 25px;
  border: 0.5px solid gray;
  margin: 2px;
}

.flags .flag-list v-img:hover{
  cursor: pointer;
  
}

</style>