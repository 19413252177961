<template>
  <!-- Header -->
  <nav class="navbar-opacity navbar navbar-expand-lg" id="reset">
    <div class="container-fluid">
      <a class="navbar-brand">
        <router-link class="nav-link click-scroll" to="/"><v-img src="@/assets/images/logo-wiwitan-2022-small.png" class="logo img-fluid" alt="Wiwitan Baru"/></router-link>
        <small>hello@wiwitanbaru.com</small>
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <!-- Home -->
          <li class="nav-item">
            <router-link class="nav-link click-scroll" to="/" v-scroll-to="'#reset'">{{ $t("header.homePageTitle") }}</router-link> 
          </li>
          <!-- About -->
          <li class="nav-item dropdown">
            <router-link class="nav-link click-scroll dropdown-toggle" to="/about" v-scroll-to="'#reset'">{{ $t("header.aboutPageTitle") }}</router-link>
            <!-- About Sub Menu -->
            <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
              <li><router-link class="dropdown-item" to="/about" v-scroll-to="'#visionmission'">{{ $t("aboutPage.visionMisson.title") }}</router-link></li>
              <li><router-link class="dropdown-item" to="/about" v-scroll-to="'#commitment'">{{ $t("aboutPage.ourCommitment.title") }}</router-link></li>
              <li><router-link class="dropdown-item" to="/about" v-scroll-to="'#ourservices'">{{ $t("aboutPage.ourServices.title") }}</router-link></li>
              <li><router-link class="dropdown-item" to="/about" v-scroll-to="'#financial'">{{ $t("aboutPage.financialAssistance.title") }}</router-link></li>
              <li><router-link class="dropdown-item" to="/about" v-scroll-to="'#working'">{{ $t("aboutPage.workingInJapan.title") }}</router-link></li>
              <li><router-link class="dropdown-item" to="/about" v-scroll-to="'#digital'">{{ $t("aboutPage.digitalEcosystem.title") }}</router-link></li>
            </ul>
          </li>
          <!-- Members -->
          <li class="nav-item">
            <router-link class="nav-link click-scroll" to="/members" v-scroll-to="'#reset'">{{ $t("header.membersPageTitle") }}</router-link>
          </li>
          <!-- News -->
          <li class="nav-item">
            <router-link class="nav-link click-scroll" to="/news" v-scroll-to="'#reset'">{{ $t("header.newsPageTitle") }}</router-link>
          </li>
          <!-- Students -->
          <li class="nav-item">
            <router-link class="nav-link click-scroll" to="/students" v-scroll-to="'#reset'">{{ $t("header.studentsPageTitle") }}</router-link>
          </li>
          <!-- Partners -->
          <li class="nav-item">
            <router-link class="nav-link click-scroll" to="/partners" v-scroll-to="'#reset'">{{ $t("header.partnersPageTTitle") }}</router-link>
          </li>
          <!-- Contact -->
          <li class="nav-item">
            <router-link class="nav-link click-scroll" to="/contact" v-scroll-to="'#reset'">{{ $t("header.contactPageTTitle") }}</router-link>
          </li>
          <!-- Language -->
          <li class="nav-item" style="width: 100px">
            <LabelSwitcher />
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Content -->
  <router-view />

  <!-- Footer -->
  <Footer />
</template>

<script>
import LabelSwitcher from './components/LabelSwitcher.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    LabelSwitcher,
    Footer
  },
  methods: {
    changeLocale: function (loc){
      this.$i18n.locale=loc
    },
  }
};
</script>

<style lang="scss">
nav {
  background: rgba(255, 255, 255, 0.1)!important;
}
</style>
