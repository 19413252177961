<template>
  <!-- Home Video -->
  <div class="home-video">
    <v-img cover class="home-video-thumbnail" src="@/assets/images/home/wiwitanyoutube.jpg" alt=""></v-img>
    <div class="home-video-content">
      <h2 class="home-video-title">Empowering People</h2>
      <h2 class="home-video-title">Improving Quality of Life</h2>
      <v-icon size="100" class="home-video-icon" icon="mdi-play-circle-outline" color="white"></v-icon>
    </div>
    <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
      style="align-items: center;" width="80%">
      <v-card width="100%">
        <iframe src="https://www.youtube.com/embed/AHbzeRE_9Ak" title="Chance by Wiwitan - English Version"
          allow="autoplay; fullscreen; gyroscope" style="position:end;width:100%;aspect-ratio:16/9;"></iframe>
        <div style="width: 80px; height: 80px; position: absolute; opacity: 0; right: 0px; top: 0px;">&nbsp;
        </div>
      </v-card>
    </v-overlay>
  </div>


  <!-- NAV ABOUT -->
  <div class="container-home">
    <div class="row-home">

      <div class="col-lg-10 col-12 text-center mx-auto">
        <h2 class="mb-5">Welcome to Wiwitan Baru</h2>
      </div>

      <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
        <div class="featured-block d-flex justify-content-center align-items-center">
          <router-link class="d-block" to="/ourservices" v-scroll-to="'#reset'">
            <v-img src="@/assets/images/home/receive.png" class="featured-block-image img-fluid" alt=""></v-img>
            <p class="featured-block-text"><strong>{{ $t("aboutPage.ourServices.title") }} </strong></p>
          </router-link>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
        <div class="featured-block d-flex justify-content-center align-items-center">
          <router-link class="d-block" to="/financial" v-scroll-to="'#reset'">
            <v-img src="@/assets/images/home/scholarship.png" class="featured-block-image img-fluid" alt=""></v-img>
            <p class="featured-block-text"><strong>{{ $t("aboutPage.financialAssistance.title") }}</strong> </p>
          </router-link>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
        <div class="featured-block d-flex justify-content-center align-items-center">
          <router-link class="d-block" to="/workjp" v-scroll-to="'#reset'">
            <v-img src="@/assets/images/home/hands.png" class="featured-block-image img-fluid" alt=""></v-img>
            <p class="featured-block-text"><strong>{{ $t("aboutPage.workingInJapan.title") }}</strong> </p>
          </router-link>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
        <div class="featured-block d-flex justify-content-center align-items-center">
          <router-link class="d-block" to="/digitaleco" v-scroll-to="'#reset'">
            <v-img src="@/assets/images/home/heart.png" class="featured-block-image img-fluid" alt=""></v-img>
            <p class="featured-block-text"><strong>{{ $t("aboutPage.digitalEcosystem.title") }}</strong> </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- MOBILE CAROUSEL -->
  <!-- <div class="carousel-container">
    <carousel :settings="settings" :breakpoints="breakpoints">
      <slide v-for="item in newsList" :key="item.id">
        <div v-if="$t('text') === 'EN'" class="mobile-carousel-if">
          <div class="carousel-news">
            <div class="carousel-news-img-wrapper">
              <img class="carousel-news-img" :src="item.img" :alt="item.titleEng" draggable="false" />
              <div class="meta-date">
                <span class="mos">{{ item.date }}</span>
              </div>
            </div>
            <div class="carousel-news-text-wrapper">
              <span class="carousel-title">{{ item.titleEng }}</span>
            </div>
            <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
              style="align-items: center;" width="80%">
              <v-card width="80vw" height="80vh" style="overflow-y: scroll;">

                <div class="carousel-news-popup-text-wrapper">
                  <span class="carousel-popup-title">{{ item.titleEng }}</span>
                  <br />
                  <span class="mos-popup">{{ item.date }}</span>
                  <br />
                  <div class="carousel-news-popup-img-wrapper">
                    <img class="carousel-news-popup-img" :src="item.img" :alt="item.titleEng" draggable="false" />
                  </div>
                  <br />
                  <br />
                  <span class="carousel-popup-desc">{{ item.descEng }}</span>
                </div>
              </v-card>
            </v-overlay>
          </div>
        </div>
        <div v-else-if="$t('text') === 'JP'" class="mobile-carousel-if">
          <div class="carousel-news">
            <div class="carousel-news-img-wrapper">
              <img class="carousel-news-img" :src="item.img" :alt="item.titleEng" draggable="false" />
              <div class="meta-date">
                <span class="mos">{{ item.date }}</span>
              </div>
            </div>
            <div class="carousel-news-text-wrapper">
              <span class="carousel-title">{{ item.titleJp }}</span>

            </div>
            <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
              style="align-items: center;" width="80%">
              <v-card width="80vw" height="80vh" style="overflow-y: scroll;">

                <div class="carousel-news-popup-text-wrapper">
                  <span class="carousel-popup-title">{{ item.titleJp }}</span>
                  <br />
                  <span class="mos-popup">{{ item.date }}</span>
                  <br />
                  <div class="carousel-news-popup-img-wrapper">
                    <img class="carousel-news-popup-img" :src="item.img" :alt="item.titleJp" draggable="false" />
                  </div>
                  <br />
                  <br />
                  <span class="carousel-popup-desc">{{ item.descJp }}</span>
                </div>
              </v-card>
            </v-overlay>
          </div>
        </div>
        <div v-else-if="$t('text') === 'ID'" class="mobile-carousel-if">
          <div class="carousel-news">
            <div class="carousel-news-img-wrapper">
              <img class="carousel-news-img" :src="item.img" :alt="item.titleId" draggable="false" />
              <div class="meta-date">
                <span class="mos">{{ item.date }}</span>
              </div>
            </div>
            <div class="carousel-news-text-wrapper">
              <span class="carousel-title">{{ item.titleId }}</span>

            </div>
            <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
              style="align-items: center;" width="80%">
              <v-card width="80vw" height="80vh" style="overflow-y: scroll;">

                <div class="carousel-news-popup-text-wrapper">
                  <span class="carousel-popup-title">{{ item.titleId }}</span>
                  <br />
                  <span class="mos-popup">{{ item.date }}</span>
                  <br />
                  <div class="carousel-news-popup-img-wrapper">
                    <img class="carousel-news-popup-img" :src="item.img" :alt="item.titleId" draggable="false" />
                  </div>
                  <br />
                  <br />
                  <span class="carousel-popup-desc">{{ item.descId }}</span>
                </div>
              </v-card>
            </v-overlay>
          </div>
        </div>
      </slide>

      <template #addons>
        <navigation>
          <template #next>
            <v-icon style="padding-left: 20px;" size="x-large">mdi-menu-right</v-icon>
          </template>
          <template #prev>
            <v-icon style="padding-right: 20px;" size="x-large">mdi-menu-left</v-icon>
          </template>
        </navigation>
      </template>
    </carousel>
  </div> -->

  <!-- DESKTOP CAROUSEL -->
  <div class="desktop-carousel-container">
    <carousel :settings="settings" :breakpoints="breakpoints">
      <slide v-for="item in sortedList()" :key="item.id">
        <div v-if="$t('text') === 'EN'" class="carousel-if">
          <div class="desktop-carousel-news-img-wrapper">
            <img class="desktop-carousel-news-img" :src="item.img" :alt="item.titleEng" draggable="false"/>
            <div class="meta-date">
              <span class="mos">{{ item.date }}</span>
            </div>
          </div>
          <div class="desktop-carousel-news-text-wrapper">
            <span class="desktop-carousel-title">{{ item.titleEng }}</span>
          </div>
          <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
            style="align-items: center;" width="80%">
            <v-card width="80vw" height="80vh" style="overflow-y: scroll;">
              <div class="carousel-news-popup-text-wrapper">
                <span class="carousel-popup-title">{{ item.titleEng }}</span>
                <br />
                <span class="mos-popup">{{ item.date }}</span>
                <br />
                <div class="carousel-news-popup-img-wrapper">
                  <img class="carousel-news-popup-img" :src="item.img" :alt="item.titleEng" draggable="false" />
                </div>
                <br />
                <br />
                <span class="carousel-popup-desc">{{ item.descEng }}</span>
              </div>
            </v-card>
          </v-overlay>
        </div>

        <div v-else-if="$t('text') === 'JP'" class="carousel-if">
            <div class="desktop-carousel-news-img-wrapper">
              <img class="desktop-carousel-news-img" :src="item.img" :alt="item.titleEng" draggable="false" />
              <div class="meta-date">
                <span class="mos">{{ item.date }}</span>
              </div>
            </div>
            <div class="desktop-carousel-news-text-wrapper">
              <span class="desktop-carousel-title">{{ item.titleJp }}</span>

            </div>
            <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
              style="align-items: center;" width="80%">
              <v-card width="80vw" height="80vh" style="overflow-y: scroll;">

                <div class="carousel-news-popup-text-wrapper">
                  <span class="carousel-popup-title">{{ item.titleJp }}</span>
                  <br />
                  <span class="mos-popup">{{ item.date }}</span>
                  <br />
                  <div class="carousel-news-popup-img-wrapper">
                    <img class="carousel-news-popup-img" :src="item.img" :alt="item.titleJp" draggable="false" />
                  </div>
                  <br />
                  <br />
                  <span class="carousel-popup-desc">{{ item.descJp }}</span>
                </div>
              </v-card>
            </v-overlay>
        </div>
        <div v-else-if="$t('text') === 'ID'" class="carousel-if">
            <div class="desktop-carousel-news-img-wrapper">
              <img class="desktop-carousel-news-img" :src="item.img" :alt="item.titleEng" draggable="false" />
              <div class="meta-date">
                <span class="mos">{{ item.date }}</span>
              </div>
            </div>
            <div class="desktop-carousel-news-text-wrapper">
              <span class="desktop-carousel-title">{{ item.titleId }}</span>

            </div>
            <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
              style="align-items: center;" width="80%">
              <v-card width="80vw" height="80vh" style="overflow-y: scroll;">

                <div class="carousel-news-popup-text-wrapper">
                  <span class="carousel-popup-title">{{ item.titleId }}</span>
                  <br />
                  <span class="mos-popup">{{ item.date }}</span>
                  <br />
                  <div class="carousel-news-popup-img-wrapper">
                    <img class="carousel-news-popup-img" :src="item.img" :alt="item.titleId" draggable="false" />
                  </div>
                  <br />
                  <br />
                  <span class="carousel-popup-desc">{{ item.descId }}</span>
                </div>
              </v-card>
            </v-overlay>
        </div>
      </slide>

      <template #addons>
        <navigation>
          <template #next>
            <v-icon style="padding-left: 0px; margin-right: 25px;" size="x-large">mdi-menu-right</v-icon>
          </template>
          <template #prev>
            <v-icon style="padding-right: 20px;" size="x-large">mdi-menu-left</v-icon>
          </template>
        </navigation>
      </template>
    </carousel>
  </div>



  <!-- DESKTOP CAROUSEL -->
  <!-- <div class="desktop-carousel-container">
    <div class="desktop-carousel-img-container" style="display: block;">
      <img id="desktop-carousel-img-id" class="desktop-carousel-img" :src="newsList[index]?.img" draggable="false" />
      <v-overlay activator="parent" location-strategy="static" scroll-strategy="block" class="d-flex justify-center"
        style="align-items: center;" width="80%">
        <v-card width="80vw" height="80vh" style="overflow-y: scroll;">
          <div class="carousel-news-popup-text-wrapper">
            <span class="carousel-popup-title">{{ newsList[index].titleEng }} </span>
            <br />
            <span class="mos-popup">{{ newsList[index].date }}</span>
            <br />
            <div class="carousel-news-popup-img-wrapper">
              <img class="carousel-news-popup-img" :src="newsList[index].img" :alt="index.titleEng" draggable="false" />
            </div>
            <br />
            <br />
            <span class="carousel-popup-desc">{{ newsList[index].descEng }}</span>
          </div>
        </v-card>
      </v-overlay>
    </div>
    <div class="desktop-carousel-title-container" v-for="(item, index) in newsList">
      <ul class="carousel_list" v-if="item.id == 0">
        <li class="carousel_list_item"><input style="display: none" :id="index" type="radio" name="basic_carousel"
            checked="checked" v-on:click="myFunction1(item.id)">
          <label class="carousel_list_item_label" :for="index" style="display: block">
            {{ item.titleEng }}</label>
        </li>
      </ul>
      <ul class="carousel_list" v-else>
        <li class="carousel_list_item"><input style="display: none" :id="index" type="radio" name="basic_carousel"
            v-on:click="myFunction1(item.id)">
          <label class="carousel_list_item_label" :for="index" style="display: block">{{ item.titleEng }}</label>
        </li>
      </ul>
    </div>
  </div> -->

  <h5 class="mb-3"></h5>
</template>

<script>
import axios from 'axios';
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      index: 0,
      newsList: [],

      // carousel
      settings: {
        wrapAround: true,
        snapAlign: "center",
        autoplay: 5000,
      },
      breakpoints: {
        300: {
          itemsToShow: 1,
        },
        700: {
          itemsToShow: 1,
        },
      },
    };
  },
  methods: {
    loadNews() {
      this.newsList = []
      const apiToken = "patrKP62cfFR0NALV.054f299c56234115a2863d59bdab6bbab8ba32f9eab13b1c5af287c0f1481676"
      const airTableApp = "appNyeeoORkiC2T7K"
      const airTableName = "newsTable"

      axios.get(`https://api.airtable.com/v0/${airTableApp}/${airTableName}`, { headers: { Authorization: "Bearer " + apiToken } })
        .then((response) => {
          // load the API response into items for datatable
          this.newsList = response.data.records.map((item) => {
            if (Object.keys(item.fields).length !== 0) {
              return {
                id: item.fields.newsId,
                img: item.fields.newsImageUpload[0].url,
                titleEng: item.fields.newsTitleEnglish,
                descEng: item.fields.newsDescriptionEnglish,
                titleJp: item.fields.newsTitleJapanese,
                descJp: item.fields.newsDescriptionJapanese,
                titleId: item.fields.newsTitleIndonesian,
                descId: item.fields.newsDescriptionIndonesian,
                date: item.fields.newsCreatedDate
              }
            }
          })
          this.newsList = this.newsList.filter(function(element) {
            return element !== undefined;
          })
          // console.warn(response)
          this.newsList = this.newsList.sort((a, b) => { return a.id - b.id; })
        }).catch((error) => {
          console.log(error)
        })
    },
    // Change image function
    myFunction1(index) {
      this.index = index
    },
    sortedList() {
      return this.newsList.sort((a, b) => { return new Date(b.date) - new Date(a.date)})
    }
  },
  created() {
    this.loadNews()
  }
};

</script>

<style>
.home-video {
  width: 100%;
  height: 87vh;
  overflow: hidden;
  justify-items: center;
}

.home-video-content {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(0, 0, 0, 0.2); */
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  /* text-shadow: rgb(0, 0, 0) 1px 0 10px; */
  text-shadow: 14px 15px 29px -8px rgba(0, 0, 0, 0.94);
  -webkit-text-shadow: 14px 15px 29px -8px rgba(0, 0, 0, 0.94);
  -moz-text-shadow: 14px 15px 29px -8px rgba(0, 0, 0, 0.94);
  margin-top: 2%;
  padding: 1%;
  border-radius: 5%;
}

.home-video-title {
  text-align: center;
  color: white;
}

.home-video-icon {
  align-items: center;
  justify-items: center;
}

.home-video-thumbnail {
  display: block;
  min-height: 100%;
  align-items: center;
  transition: all 0.5s;
  object-fit: cover;
}

.home-video:hover .home-video-thumbnail {
  opacity: 0.7;
}

.carousel-if {
  width: 100vw;
  height: 90vh;
  justify-content: center;
  align-items: center;
}

.mobile-carousel-if {
  width: 100vw;
  height: 50vh;
}

.dekstop-carousel-news:hover .desktop-carousel-news-img {
  opacity: 0.7;
}
</style>