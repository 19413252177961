import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/styles.scss'
import '../src/assets/js/click-scroll.js'
import '../src/assets/js/counter.js'
import '../src/assets/js/custom.js'
import '../src/assets/js/jquery.sticky.js'
import '../src/assets/js/jquery.min.js'
import '../src/assets/js/bootstrap.min.js'
import '../src/assets/js/owl.carousel.min.js'
import '../src/assets/js/mainCarousel.js'
import i18n from '@/assets/label/label'
import VueScrollTo from 'vue-scrollto'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { mdi } from 'vuetify/iconsets/mdi'

loadFonts()

createApp(App).use(store).use(router).use(i18n).use(VueScrollTo).use(vuetify).use(mdi).mount('#app')
